<!--找回密码第一步-->
<template>
  <!--第一版忘记密码（找回密码是分为第一步和第二步的）-->
  <!-- <el-form
		ref="firstStepForm"
		:model="formData"
		:rules="rules"
		label-width="120px"
		class="findPwd-form">
		<el-form-item prop="phone" label="手机号">
			<el-input v-model="formData.phone" placeholder="输入手机号" />
		</el-form-item>
		<el-form-item prop="captcha" label="验证码">
			<el-input v-if="!isShowCaptcha" v-model="formData.captcha" placeholder="输入验证码">
				<el-button slot="append" @click="onGetcaptcha">获取验证码</el-button>
			</el-input>
			<el-input v-else v-model="formData.captcha">
				<el-button disabled slot="append">{{ countdown }}后重新获取</el-button>
			</el-input>
		</el-form-item>
		<el-form-item>
			<el-button type="primary" :disabled="disabledBtn" class="first-step-btn findPwd-btn" @click="onNext">下一步</el-button>
		</el-form-item>
	</el-form> -->
  <!--第二版忘记密码手机验证和新密码放在同一个页面中，所以没有第一步和第二步-->
  <div class="about-login-form-wrap">
    <header class="title-header clearfix">
      <h3 class="fl">找回密码</h3>
      <el-button type="text" @click="toLoginHandle" class="fr">返回登录</el-button>
      <!-- <h4 class="fr">登录</h4> -->
    </header>
    <el-form
      ref="firstStepForm"
      :model="formData"
      :rules="rules"
      label-width="120px"
      class="findPwd-form">
      <el-form-item prop="phone" label="手机号码">
        <el-input v-model="formData.phone" placeholder="输入手机号" />
      </el-form-item>
      <el-form-item prop="captcha" label="短信验证码">
        <el-input v-if="!isShowCaptcha" v-model="formData.captcha" placeholder="输入验证码">
          <el-button slot="append" @click="onGetcaptcha">发送验证码</el-button>
        </el-input>
        <el-input v-else v-model="formData.captcha">
          <el-button disabled slot="append">{{ countdown }}后重新获取</el-button>
        </el-input>
      </el-form-item>
      <el-form-item prop="newPassword" label="新密码">
        <el-input v-model="formData.newPassword" type="password" placeholder="设置新密码" auto-complete="new-password" />
      </el-form-item>
      <el-form-item prop="checkPass" label="确认密码">
        <el-input v-model="formData.checkPass" type="password" placeholder="确认密码" auto-complete="new-password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :class="submitBtn" @click="submitForm">提交</el-button>
      </el-form-item>
      <!-- <el-form-item>
        <el-button type="primary" :disabled="disabledBtn" class="first-step-btn findPwd-btn" @click="onNext">下一步</el-button>
      </el-form-item> -->
    </el-form>
  </div>
</template>
<script>
import { RULES_PHONE } from '@/utils/rules'
import Cookies from "js-cookie"
import {
	getCaptchaApi
} from '@/api/login/register'
import {
  resetPwdApi,
  resetPwdSendMessageApi
} from '@/api/login/login'
export default {
	data() {
    const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'))
			} else if (value !== this.formData.newPassword) {
				callback(new Error('两次输入密码不一致!'))
			} else {
				callback()
			}
		}
		return {
			isShowCaptcha: false,
			countdown: 60,
			formData: {
				phone: '',
				captcha: '',
        newPassword: '',
				oldPassword: '',
				captchaPrimaryKey: '',
				checkPass: ''
			},
			rules: {
				phone: [
					{ required: true, trigger: "blur", message: "手机号不能为空" },
					RULES_PHONE()
				],
				captcha: [
					{ required: true, trigger: "blur", message: "验证码不能为空" }
				],
        oldPassword: [
					{ required: true, trigger: "blur", message: "旧密码不能为空" }
				],
				newPassword: [
					{ required: true, trigger: "blur", message: "新密码不能为空" }
				],
				checkPass: [
					{ required: true, trigger: "blur", message: "确认密码不能为空" },
					{ validator: validatePass2, trigger: 'blur' }
				]
			}
		}
	},
	computed: {
		disabledBtn() {
			return false
		},
    submitBtn() {
      const { newPassword, checkPass, phone, captcha } = this.formData
      const len1 = newPassword.trim().length
      const len2 = phone.trim().length
      const len3 = checkPass.trim().length
      const len4 = captcha.trim().length
      if (len1 && len2 && len3 > 0 && len4 > 0) {
        return 'first-step-btn editPwd-btn form-btn'
      } else {
        return 'first-step-btn editPwd-btn form-btn disabled'
      }
    }
	},
  watch: {
    formData: {
      handler: function (newVal, oldVal) {
        // console.log(newVal)
      },
      deep: true
    }
  },
  mounted() {
    // console.log(this.formData)
  },
  created() {
    // console.log(this.formData)
  },
	methods: {
		// 下一步
		onNext() {
			this.$refs['firstStepForm'].validate(async (valid) => {
				if (valid) {
					this.$emit('componentChange', 'secondStep')
				} else {
					console.log('error submit!!');
					return false
				}
			})
		},
		// 获取验证码按钮
		async onGetcaptcha() {
			this.$refs['firstStepForm'].validateField('phone', async (validate) => {
				if (validate) {
					return true
				} else {
					await this.onGetcaptchaData()
				}
			})
		},
		// 获取验证码调接口
		async onGetcaptchaData() {
			
			/* const obj = {
				captchaSceneEnum: 'RETRIEVE_PASSWORD',
				phone: this.formData.phone
			}
			let res = await getCaptchaApi(obj)
			if (res && res.code === 200) {
				const captchaPrimaryKey = res.data && res.data.msg
				let obj = {
					phone: this.formData.phone,
					captcha: this.formData.captcha,
					captchaPrimaryKey
				}
				Cookies.set('findPwdObj', JSON.stringify(obj))
			} */
      const obj = {
        phone: this.formData.phone
      }
      let res = null
      try {
        res = await resetPwdSendMessageApi(obj)
        if (res && res.code === 200) {
          this.isShowCaptcha = true
          this.countdown = 60
          this.onCountdown()
          const captchaPrimaryKey = res && res.msg
          this.formData.captchaPrimaryKey = captchaPrimaryKey
          let obj = {
            phone: this.formData.phone,
            captcha: this.formData.captcha,
            captchaPrimaryKey
          }
          Cookies.set('findPwdObj', JSON.stringify(obj))
          console.log(this.formData)
        }
      } catch (err) {
        console.log(err.message)
        this.$confirm(err.message + '请检查手机号是否填写正确', '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          showCancelButton: false,
          center: true
        }).then(() => {
          /* this.$router.push({
            path: 'register'
          }) */
        }).catch((err) => {
          console.log(err)
        })
      }
		},
		// 倒计时函数
		onCountdown() {
			let timer = 0
			clearInterval(timer)
			timer = setInterval(() => {
				this.countdown--
				if (this.countdown === 0) {
					clearInterval(timer)
					this.isShowCaptcha = false
				}
			}, 1000)
		},
    submitForm(formName) {
			this.$refs['firstStepForm'].validate(async (valid) => {
				if (valid) {
					// const findPwdObj = JSON.parse(Cookies.get('findPwdObj'))
					// this.formData.phone = findPwdObj.phone
					// this.formData.captchaPrimaryKey = findPwdObj.captchaPrimaryKey
					// this.formData.captcha = findPwdObj.captcha
          console.log(this.formData)
					let res = await resetPwdApi(this.formData)
					if (res && res.code === 200) {
						this.$emit('componentChange', 'editPwdSucess')
					}
				} else {
					console.log('error submit!!');
					return false
				}
			})
		},
    toLoginHandle() {
			// this.$emit('componentChange', 'login')
      this.$eventBus.$emit('backToLogin', 'findPwd')
		}
	}
}
</script>
<style lang="scss" scoped>
@import '~@/assets/styles/login.scss';
</style>
