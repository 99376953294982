import request from '@/utils/request'

// 强制修改密码
export function enforceUpdatePwdApi(data) {
  return request({
    url: '/system/user/profile/enforceUpdatePwd',
    method: 'put',
    params: data
  })
}
// 找回密码（点提交按钮）
export function resetPwdApi(data) {
  return request({
    url: '/system/user/sms/resetPwd',
    method: 'put',
    params: data
  })
}
// 重置密码发送短信
export function resetPwdSendMessageApi(data) {
  return request({
    url: '/system/user/push/resetPwd',
    method: 'put',
    params: data,
    noGloablErrMsg: true // 当code === 500时，需要单独处理，不需要全局报红色提示信息
  })
}
