<!--找回密码-->
<template>
	<el-container class="findPwd-container layout-container">
		<el-header class="findPwd-header">
			<div>
				<img src="../../../assets/images/logo-pdBox.png" />
				<span class="header-text">找回密码</span>
			</div>
      <!-- <div v-if="showQuickLogin">
        <el-button type="text" @click="toLoginHandle">立即登录</el-button>
      </div> -->
		</el-header>
		<el-main class="findPwd-main">
			<transition name="component-fade" mode="out-in">
				<component :is="which_to_show" @componentChange="changeHandle"></component>
			</transition>
		</el-main>
		<el-footer class="el-login-footer">
      <!-- <span>Copyright © 2018-2021 winning All Rights Reserved.</span> -->
      <div>
        <img src="../../../assets/images/icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
        <span style="padding-left:5px;">{{ opennessConfig.webSystemLatestVersion }}</span>
      </div>
      <div>
        <img src="../../../assets/images/beian-icon.png" alt="" style="width: 20px;height: 20px;margin-top:10px;float: left;"/>
        <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602006620"
        style="display:inline-block;text-decoration:none;">
        <p style="padding-left:5px;color:#939393;">沪公网安备 31010602006620号</p></a>
      </div>
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank" style="padding: 0;" class="baNum">沪ICP备20018067号-2</a>
      </div>
      <div><span style="font-size: 18px;vertical-align: middle;">©</span>2022 上海卫心科技有限公司</div>
      <div>服务协议</div>
      <div>法律声明及隐私权政策</div>
		</el-footer>
	</el-container>
</template>
<script>
import FirstStep from './FindPwdFirst'
import SecondStep from './FindPwdSecond'
import EditPwdSucess from './EditPwdSucess'
import { opennessConfigList } from "@/api/system/config";
export default {
	components: {
		FirstStep,
		SecondStep,
		EditPwdSucess
	},
	data() {
		return {
      /*系统开发配置信息*/
      opennessConfig:{
        /*安卓app下载二维码base64*/
        androidAppDownloadQrCode:"",
        webSystemLatestVersion: '' // pc版本号
      },
			which_to_show: FirstStep
		}
	},
  computed: {
    showQuickLogin() {
			if (this.which_to_show === EditPwdSucess) {
				return false
			} else {
				return true
			}
		}
  },
	created() {
    this.getOpennessConfig()
		this.$eventBus.$on('backToLogin', (val) => {
			console.log(val)
			this.which_to_show = FirstStep
		})
	},
	methods: {
    /*获取系统开放配置信息*/
    getOpennessConfig(){
      opennessConfigList().then(res=>{
        this.opennessConfig=res.data
      })
    },
    toLoginHandle() {
			this.$emit('componentChange', 'login')
		},
		changeHandle(component) {
			if (component === 'secondStep') {
				this.which_to_show = SecondStep
			} else if (component === 'firstStep') {
				this.which_to_show = FirstStep
			} else if (component === 'editPwdSucess') {
				this.which_to_show = EditPwdSucess
			}
		}
	}
}
</script>
<style lang="scss" scoped>
.baNum:hover{
  text-decoration:underline;
}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active for below version 2.1.8 */ {
  opacity: 0;
}
.findPwd-container {
	.findPwd-header {
		height: 50px !important;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #FFFFFF;
		box-shadow: 0px 4px 6px 0px rgba(189, 192, 199, 0.7);
		img {
			vertical-align: middle;
		}
		.header-text {
			display: inline-block;
			border-left: 2px solid #C8D9FA;
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			margin-left: 10px;
			padding-left: 10px;
		}
	}
	.findPwd-main {
		position: relative;
		.findPwd-form {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 40%;
			/* margin: auto auto; */
			::v-deep.findPwd-btn {
				width: 100%;
        border: 1px solid #DCDFE6 !important;
				background: linear-gradient(90deg, #00AAFF, #2B60F8);
				border-radius: 2px;
			}
		}
	}
  .el-login-footer {
    height: 40px!important;
    line-height: 40px;
    width: 100%;
    height: 40px;
    background:#EBEFF7;
    opacity: 0.8;
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: #999999;
    font-family: Arial;
    font-size: 14px;
    letter-spacing: 1px;
    div {
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>

