var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "editPwdForm",
      staticClass: "findPwd-form",
      attrs: { model: _vm.formObj, rules: _vm.rules, "label-width": "120px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { prop: "newPassword", label: "新密码" } },
        [
          _c("el-input", {
            attrs: { type: "password", placeholder: "设置新密码" },
            model: {
              value: _vm.formObj.newPassword,
              callback: function ($$v) {
                _vm.$set(_vm.formObj, "newPassword", $$v)
              },
              expression: "formObj.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { prop: "checkPass", label: "确认密码" } },
        [
          _c("el-input", {
            attrs: { type: "password", placeholder: "确认密码" },
            model: {
              value: _vm.formObj.checkPass,
              callback: function ($$v) {
                _vm.$set(_vm.formObj, "checkPass", $$v)
              },
              expression: "formObj.checkPass",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              staticClass: "first-step-btn editPwd-btn",
              attrs: { type: "primary" },
              on: { click: _vm.submitForm },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }